import React from 'react'
import "./SecondaryHeading.css"
export const SecondaryHeading = (props) => {
    return (
        <>
            <h2 className="secondaryHeading">
               {props.title}
            </h2>
        </>
    )
}
export const TitleMd = (props) => {
    return (
        <>
            <h2 className="title_md">
            {props.title}
            </h2>
        </>
    )
}
export const BannerTitleLg = (props) => {
    return (
        <>
            <h2 className="banner_title_lg">
                {props.title}
            </h2>
        </>
    )
}
export const BannerTitlemd = (props) => {
    return (
        <>
            <h2 className="banner_title_md">
                {props.title}
            </h2>
        </>
    )
}

// export default SecondaryHeading