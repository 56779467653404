import React from 'react';
import styles from '../../../css/Footer.module.css';
import { logo2 } from "../../../assests/index";
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
// FaInstagram, FaLinkedin, FaTwitter

function Footer() {
    return (
        <>
            <footer className={styles.footer}>
                <div className={styles.container}>
                    <div className={styles.contactDetails}>
                        <h2>CONTACT</h2>
                        <div className={styles.contactDetailsP}>

                            <p><FaPhone style={{ color: '#fff' }} />

                                <p className={styles.contactP}>
                                    +92 337 8426564

                                </p>

                            </p> {/* Tomato color for phone */}
                            <p><FaPhone style={{ color: '#fff' }} />


                                <p className={styles.contactP}>

                                    +44 7438 851458
                                </p>

                            </p> {/* Tomato color for phone */}
                            <p><FaEnvelope style={{ color: '#fff' }} />

                                <p className={styles.contactP}>

                                    info@techxudo.com
                                </p>

                            </p> {/* Blue color for email */}
                        </div>
                    </div>
                    <div className={styles.VerticalLine}>


                    </div>
                    <div className={styles.logoContainer}>
                        <img src={logo2} alt="Company Logo" className={styles.logo} />
                    </div>
                    <div className={styles.VerticalLine}>


                    </div>

                    <div className={styles.address}>
                        <h2 style={{ fontWeight: '20px' }}>ADDRESS</h2>
                        <div className={styles.contactItem}>
                            <p className={styles.contactP}>
                                <FaMapMarkerAlt className={styles.Addressicon} />
                                <p style={{ marginRight: '30px' }}>

                                    17 Sunbeam Close Smithswood Birmingham, UK
                                </p>
                            </p>
                        </div>
                        <div className={styles.contactItem}>
                            <p className={styles.contactP}>
                                <FaMapMarkerAlt className={styles.Addressicon} />
                                R-13 Gulshan e Akbar Malir Karachi, Pakistan
                            </p>
                        </div>
                    </div>
                </div>
                <div className={styles.SocialMediaIcons}>
                    <hr></hr>
                    <ul className={styles.f_social_media}>
                        <li >
                            <a href="https://twitter.com/weberous" rel="noopener noreferrer" target="_blank">
                                <img className={styles.icon} src="https://www.weberous.com/wp-content/themes/weberous/images/twitter.svg" alt="twitter" style={{ width: "29" }} />
                                {/* <span>twitter</span> */}
                            </a>
                        </li>
                        <div className={styles.SocialIconsVerticalLine}></div>
                        <li>
                            <a href="https://www.facebook.com/Weberous/" rel="noopener noreferrer" target="_blank">
                                <img className={styles.icon} src="https://www.weberous.com/wp-content/themes/weberous/images/facebook.svg" alt="facebook" style={{ width: "14" }} />
                                {/* <span>facebook</span> */}
                            </a>
                        </li>
                        <div className={styles.SocialIconsVerticalLine}></div>
                        <li>
                            <a href="https://www.instagram.com/weberous/" rel="noopener noreferrer" target="_blank">
                                <img className={styles.icon} src="https://www.weberous.com/wp-content/themes/weberous/images/instagram.svg" alt="instagram" style={{ width: "27" }} />
                                {/* <span>instagram</span> */}
                            </a>
                        </li>
                        <div className={styles.SocialIconsVerticalLine}></div>
                        <li>
                            <a href="https://www.pinterest.com/weberous/" rel="noopener noreferrer" target="_blank">
                                <img className={styles.icon} src="https://www.weberous.com/wp-content/themes/weberous/images/pinterest.svg" style={{ width: "26" }} alt="pinterest" />
                                {/* <span>pinterest</span> */}
                            </a>
                        </li>
                        <div className={styles.SocialIconsVerticalLine}></div>
                        <li>
                            <a href="https://www.linkedin.com/company/weberous-web-design/" rel="noopener noreferrer" target="_blank">
                                <img className={styles.icon} src="https://www.weberous.com/wp-content/themes/weberous/images/linkedin.svg" style={{ width: "22", position: "relative", top: '-2px' }} alt="linkedin" />
                                {/* <span>linkedin</span> */}
                            </a>


                        </li>
                    </ul>
                    <hr></hr>
                </div>




                <div className={styles.footerBottom}>
                    <p className={styles.footerBotttomText}>© 2024 | All Rights Reserved | techxudo</p>
                </div>
            </footer>
        </>
    );
}

export default Footer;
