import React from "react";
import { ButtonColor, ButtonWhatsApp, Heading } from "../../index";
import { hermain } from "../../../assests/index";
import './HeroSection.css'

function HeroSection() {
    return (
        <>
       
            
            <section className="animated-div">
                <div className = "home__section">
                {/* <Particle/> */}
                <div className="home__main__container">
                <div className="home__main__container" style={{ position: 'relative', zIndex: 10 }}>
                        <Heading title="Transforming Dreams Into Digital Reality." />
                        <h5 className="home__main__container__left-text">We produce a technical image of your innovative mindset.
                            Say Goodbye to your Digital Struggles.
                        </h5>
                        <div className="home__button__btn__both">
                            <div className="home__button__btn__both-left" id="tour_read_quran">
                                <ButtonColor
                                    btnName="Hire Now!"
                                    proVisit="contact"

                                />
                            </div>
                            <div className="home__button__btn__both-right">
                              <ButtonWhatsApp 
                                btnName="Hire Now!"
                                    proVisit="https://wa.link/0wyo6k"
                                    btnNameWhite = "Quick Connect"
                                    btnColorWhite = "#666"
                              />
                            </div>
                        </div>
                    </div>

                    <div className="home__main__container__right">
                        <img src={hermain} className="home__main__container__right-img about_animation" alt="heroSection" />
                    </div>
                </div>
                </div>
            </section>
            
        </>
    )
}

export default HeroSection;