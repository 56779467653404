import './App.css';
import { AppNavbar, Footer } from './Component';
import { 
  Home, Services, BottomToTop, AboutPage, Work, TeamPage, ContactPage
} from "../src/Component/index"
import { Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
<>
<ToastContainer />
    <div className="App">
      <AppNavbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/services' element={<Services />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/work' element={<Work />} />
        <Route path='/team' element={<TeamPage />} />
        <Route path='/contact' element={<ContactPage />} />
      </Routes>
      <Footer />
      <BottomToTop />
    </div>
</>
  );
}

export default App;
