import React from "react";
import MetaData from "../../MetaData";
import { 
    ContactForm,
} from "../../index"

function ContactPage() {
    return (
        <>
            <MetaData title="Techxudo | Contact" />
            <ContactForm serviceLimit = {8} />
        </>
    )
}

export default ContactPage;