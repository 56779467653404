import React from 'react'
import "./Heading.css"

export const Heading = (props) => {
    return (
        <>
        {/* <h1 className="heading">
            {props.title}
        </h1> */}
        <div class="heading">
                <h1 class="heading_h1">{props.title}</h1>
        </div>
        </>
    )
}

export const SubHeading = (props) => {
    return (
        <>
        {/* <h1 className="heading">
            {props.title}
        </h1> */}
        <div class="subheading">
                <span className= "heading_span">{props.subTitle}</span><h1 class="heading_h1">{props.title}</h1>
        </div>
        </>
    )
}