import React from "react";
import MetaData from "../../MetaData";
import {
    Team,
    Banner
} from "../../index"

function TeamPage() {
    return (
        <>
            <MetaData title="Techxudo | Team" />
            <div className='main_margin'>
                <Team teamLimit={10}
                    title="Our"
                    subTitle="Team"
                    secondaryTitle="Meet the team of creative peoples." />
            </div>
            <Banner />
        </>
    )
}

export default TeamPage;