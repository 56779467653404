import React, { useState, useRef } from 'react';
import styles from '../../../css/Services.module.css';
import { services } from "../../../assests/dummyData";
import { SecondaryHeading, TitleMd, SubHeading, Banner } from "../../index";

function Services({serviceLimit}) {
  const INITIAL_LIMIT = 4; // Define initial limit
  const [limit, setLimit] = useState(serviceLimit); // Initial limit
  const sectionRef = useRef(null); 

  const handleShowMore = () => {
    setLimit(prevLimit => prevLimit + 4);
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' }); // Scroll to the top of the section
    }
  };
  const handleShowLess = () => {
    setLimit(INITIAL_LIMIT); // Reset the limit to initial value
    window.scrollTo({ top: 20, left: 0, behavior: 'smooth' });
  };

  const handlechatClick = () => {
    window.open("https://wa.me/923378426564?text=");
  };

  return (
  <>
      <section className={`${styles.services} animated-div`}  >
      {/* <SectionHeading title="Our Services" /> */}
      <SubHeading subTitle="Our" title="Services" />
      <TitleMd title = "We provide a wide range of services that will help you to mold your presence as a digital expert." />
      <div className={styles.servicesContainer}>
        {services.slice(0, limit).map((service, index) => (
          <div key={index} className={styles.serviceCard}>
            <div className={styles.iconContainer}>
              <img src={service?.img} alt='services' />
            </div>
            <div className={styles.servciesCardDetails}>
              <SecondaryHeading title={service.title} />
              <h5 className = {styles.serviceText}>{service.description}</h5>
              <div className={styles.tags}>
                {service.tags.map((tag, idx) => (
                  <span key={idx}>{tag}</span>
                ))}
              </div>
              <button onClick={handlechatClick} className={styles.button}>
                Let's chat <span>&rarr;</span>
              </button>
             
            </div>
          </div>
        ))}
      </div>
      {services.length > limit ? (
        <button onClick={handleShowMore} className={styles.showMoreButton}>
          Show More
        </button>
      ) : (
        limit > INITIAL_LIMIT && (
          <button onClick={handleShowLess} className={styles.showMoreButton}>
            Show Less
          </button>
        )
      )}
    </section>
    <Banner />
  </>
  );
}

export default Services;
