import React from 'react';
import { SubHeading, TitleMd} from "../../index";
import styles from '../../../css/Clients.module.css';
import { clients } from '../../../assests/dummyData';



const PartnersSection = () => {


  return (
	<section className={`${styles.clients} mb-9 my-9 animated-div`}>
       <SubHeading subTitle = "Our" title = "Partners"  />
	   <TitleMd title= "What Company's say about us..." /> 
      <div className={`${styles.slider} mt-4`}>
        <div className={styles.slideTrack}>
          {clients?.map((client, index) => ( // Concatenate clients to make them repeat
            <div key={index} className={styles.slide}>
              <img src={client.logo} alt="Client Logo" className={styles.clientLogo} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PartnersSection;
