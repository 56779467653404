import React, { useState } from "react";
import { Navbar, Container, Nav, Offcanvas } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './AppNavbar.css'
import { BiMenuAltRight } from 'react-icons/bi'
import { ButtonWhatsApp } from "../Buttons/Button";
import { logo} from "../../../assests/index"

function AppNavbar() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleNavClick = () => {
        setShow(false);
    };

    const goToBtn = () => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	};

    return (
        <>
            <Navbar expand="lg" className="main-navbar" sticky="top">

                <Container>

                    <div  className="main_navbar_logo">
                    {/* <img src = {logo} alt="techxudoLogo" /> */}
                    <Link to='/' className="main_navbar_logo" >
                    <img src = {logo} alt="techxudoLogo" />
                    </Link>
                    </div> 
                    {/* <Link to="/" className="navbar-brand title__color">Techxudo</Link> */}
                    <button onClick={handleShow} className="mobile__navbar"  >
                        <BiMenuAltRight onClick={handleShow} className="me-2" />
                    </button>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" >
                        <Nav className="ms-auto nav-font">

                            <Link to='/' className="NavUl" onClick={goToBtn} >Home</Link>
                            <Link to='about' className="NavUl" onClick={goToBtn}>About</Link>
                            <Link to='services' className="NavUl" onClick={goToBtn}>Services</Link>
                            <Link to='work' className="NavUl" onClick={goToBtn}>Our Work</Link>
                            {/* <Link to='team' className="NavUl" onClick={goToBtn}>Team</Link> */}
                            <Link to='contact' className="NavUl" onClick={goToBtn}>Contact</Link>
                        </Nav>
                        <ButtonWhatsApp 
                                    proVisit="https://appt.link/meet-with-syed-bakhtawar-fahim"
                                    btnNameWhite = "Let's Talk"
                                    btnColorWhite = "#fff"
                              />
                    </Navbar.Collapse>
                </Container>
            </Navbar>



            <Offcanvas show={show}
                onHide={handleClose}
                placement="end"
                name="end"
                className="mobile_version_offcanvas_nav"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="mobile_version_offcanvas_nav-title">
                        <Link to="/" className="mobile_version_offcanvas_nav-title-link">Techxudo</Link>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>


                    <div className='mobile_version_links'>
                        <Link to='/' className="NavUl" onClick={handleNavClick}>Home</Link>
                        <Link to='about' className="NavUl" onClick={handleNavClick}>About</Link>
                        <Link to='services' className="NavUl" onClick={handleNavClick}>Services</Link>
                        <Link to='work' className="NavUl" onClick={handleNavClick}>Our Work</Link>
                        {/* <Link to='team' className="NavUl" onClick={handleNavClick}>Team</Link> */}
                        <Link to='contact' className="NavUl" onClick={handleNavClick}>Contact</Link>
                        <ButtonWhatsApp 
                                proVisit="https://appt.link/meet-with-syed-bakhtawar-fahim"
                                btnNameWhite = "Let's Talk"
                                btnColorWhite = "#666"
                        />
                    </div>
                </Offcanvas.Body>
            </Offcanvas>

        </>
    )
}

export default AppNavbar;

// 01AC67
// 26A567
// #F5F9FC
// #007300