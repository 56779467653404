import React from 'react'
import MetaData from "../../MetaData";
import { 
    Banner,
    ShowCase
} from "../../index"


const Work = () => {
  return (
    <>
        <MetaData title = "Our Work" />
        <div className='main_margin'>
        <ShowCase workLimit = {19} 
            title = "Selected"
            subTitle = "Cases"
            secondaryTitle = "Showcase of Our Expertise"
        />
        </div>
        <Banner />
    </>
  )
}

export default Work