import React from "react";
import { ButtonColor, SubHeading } from "../../index";
import { aboutUs } from "../../../assests/index";
import './About.css'


function About() {

    return (
        <React.Fragment>
            <section className="about__section animated-div">
                <div className="about__main__container">

                    <div className="about__main__container__left">
                        <SubHeading subTitle="About" title="Us" />
                        <h5 className="about__main__container__left-text">
                            At Techxudo, we don't just embrace the future; we shape it. As serving in IT System Custom
                            Software Development, we are dedicated to elevating businesses through expert services in Web and Mobile applications, Smart contracts, E-commerce, CMS, CRM, graphic designing, and digital marketing.

                            With years of hands-on experience and a stellar track record of 99% satisfied clients, Techxudo
                            is your trusted partner for navigating the dynamic digital landscape. We pride ourselves on
                            delivering customized solutions that meet and exceed your expectations. From concept to
                            execution, we prioritize faster time to market, ensuring your business stays ahead in the
                            competitive digital sphere.
                        </h5>
                        <div className = "about_section_btn">
                        <ButtonColor
                            btnName="View More"
                            proVisit="/about"
                        />
                        </div>
                    </div>
                    <div className="about__main__container__right">
                        <img src={aboutUs}  alt="techxudo" className="about__main__container__right-img about-img about_animation" />
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default About;