import React, { useRef } from 'react';
import { SubHeading, TitleMd} from "../../index";
import emailjs from '@emailjs/browser'
import { toast } from 'react-toastify';
import { barcode } from "../../../assests/index";
import { BiUserCircle } from "react-icons/bi"
import { FiHeadphones, FiHelpCircle } from "react-icons/fi"
import { IoLocationOutline } from "react-icons/io5"
// import { Phone, MapPin, Mail, User } from 'lucide-react';
// import { BsFacebook } from "react-icons/bs"
// import { AiFillBehanceCircle, AiFillInstagram, AiFillLinkedin } from "react-icons/ai"
// import { Link } from 'react-router-dom';
import "./Contact.css"


const ContactForm = () => {
  const form = useRef();
  // const [data, userData] = useState("")

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_hof3bal', 'template_hcmia8j', form.current, 'BKFDjo4XHTUwQeATt')
      .then((result) => {
          // console.log(result.text);
          toast.success("Thank you for contacting us. We'll get back to you soon..!");
          form.current.reset();
      }, (error) => {
          // console.log(error.text);
          toast.error("Something went wrong, Please try again later...");
          form.current.reset();
      });
  };
  return (
<>
<section className='contact bg-top animated_div mb-15'>
        <div className='container'>
          <div className='heading-title my-10'>
          <SubHeading subTitle = "Contact" title = "Us" />
            <br />
            <TitleMd title = "Got questions? Ideas? Fill out the form below to get our proposal." />
          </div>
          <div className='content py flex1'>
            <div className='left w-30'>
              <div className='contact-deatils'>
             
                <div className='box'>
                  <FiHeadphones size={30} className='icons' />
                  <h3>Phone</h3>
                  <span>+44 7438 851458</span>
                  <span>+92 337 8426564</span>
                </div>
                <div className='box'>
                  <IoLocationOutline size={30} className='icons' />
                  <h3>Office Locations</h3>
                  <span>17 Sunbeam Close Smithswood Birmingham, UK</span>
                  <span>R-13 Gulshan e Akbar Malir Karachi, Pakistan</span>
                </div>
                <div className='box'>
                  <FiHelpCircle size={30} className='icons' />
                  <h3>info@techxudo.com</h3>
                  <span>Drop us a line anytime!</span>
                </div>
                <div className='box'>
                  <BiUserCircle size={30} className='icons' />
                  <h3>info@techxudo.com</h3>
                  <span>Career at Techxudo</span>
                </div>
                <img src = {barcode}  alt= "barcode" className = "barcode"/>
                
              </div>
              {/* <ul>
                <li className='icon'>
                  <BsFacebook size={25} />
                </li>
                <li className='icon'>
                  <AiFillBehanceCircle size={25} />
                </li>
                <li className='icon'>
                  <AiFillInstagram size={25} />
                </li>
                <li className='icon'>
                  <Link href='https://www.linkedin.com/company/xnitia/?lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_companies%3BJ5iv2z2bTIGeCVp2YDy5Iw%3D%3D' rel="noopener noreferrer" target="_blank">
                    <AiFillLinkedin size={25} />
                  </Link>
                </li>
              </ul> */}
            </div>
            <div className='right w-70'>
              <TitleMd title='Make an online enquiry' />
              <p className='desc-p'>Got questions? Ideas? Fill out the form below to get our proposal. </p>

              <form ref={form} onSubmit={sendEmail}>
                <div className='grid-2'>
                  <div className='inputs'>
                    <span>Name</span>
                    <input type='text' name = "userName" required/>
                  </div>
                  <div className='inputs'>
                    <span>Email</span>
                    <input type='text' name = "userEmail" required/>
                  </div>
                </div>
                <div className='grid-2'>
                  <div className='inputs'>
                    <span>your budget</span>
                    <input type='text' name = "userBudget" required/>
                  </div>
                  <div className='inputs'>
                    <span>timeframe</span>
                    <input type='text' name = "userTimeFrame" required/>
                  </div>
                </div>
                <div className='inputs'>
                  <span>TELL US A BIT ABOUT YOUR PROJECT*</span>
                  <textarea cols='30' rows='10' name = "userDesc" required></textarea>
                </div>
                <input type="submit" value="Send" className='contact_btn' />
                {/* <button className='button-primary'>Submit</button> */}
              </form>
            </div>
          </div>
        </div>
      </section>
</>
  );
};

export default ContactForm;