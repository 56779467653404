import React from "react";
import MetaData from "../../MetaData";
import { 
    HeroSection,
    Services,
    About,
    MissionVission,
    ShowCase,
    Testimonial,
    PartnersSection,
    ContactForm,
} from "../../index"

function Home() {
    return (
        <>
            <MetaData title="Techxudo | Your Digital Partner" /> 
            <HeroSection /> 
            <About />
            <Services serviceLimit = {4}  />
            <MissionVission />
            <ShowCase workLimit  = {3} 
             title = "Selected"
             subTitle = "Cases" />
             <Testimonial />
             <PartnersSection />
             {/* <Team 
                teamLimit  = {6} 
                 title = "Our"
                subTitle = "Team" 
             /> */}
             <ContactForm/>
        </>
    )
}

export default Home;