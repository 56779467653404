import React from "react";
import "./Button.css"
import { Link } from 'react-router-dom';

function ButtonColor(props) {
    const goToBtn = () => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	};

    return (
        <React.Fragment>
            <div className="main__button">
                <div className="button">
                    <Link to={props.proVisit} className='button__btn'
                    onClick = {goToBtn}
                    >{props.btnName}
                    </Link>
                </div>
            </div>
        </React.Fragment>
    )
}

function ButtonWhite(props) {
    return (
        <React.Fragment>
            <div className="button__white main__button button">
                <Link
                    to={props.proVisit}
                    className="button__btn-white"
                    style={{ color: props.btnColorWhite }}

                > {props.btnNameWhite} </Link>
            </div>
        </React.Fragment>
    )
}
function ButtonWhatsApp(props) {
    return (
        <React.Fragment>
            <div className="button__white main__button button">
                <a
                    href={props.proVisit}
                    className="button__btn-white"
                    style={{ color: props.btnColorWhite }}
                    rel="noopener noreferrer" target="_blank"

                > {props.btnNameWhite} </a>
            </div>
        </React.Fragment>
    )
}
function ButtonNavbar(props) {
    return (
        <React.Fragment>
            <Link
                to={props.proVisit}
                className="button_navbar"
            // style={{ color: props.btnName }}

            > {props.btnName} </Link>

        </React.Fragment>
    )
}
function DonateButton(props) {
    return (
        <React.Fragment>
            <div className="donate_button">
                <Link to={props.proVisit} className="donate_button-btn"> {props.btnDonatetitle} 💰 </Link>
                {/* <AiOutlineArrowRight className="donate_button-btn-icon" /> */}
            </div>
        </React.Fragment>
    )
}
export {
    ButtonColor,
    ButtonWhite,
    DonateButton,
    ButtonNavbar,
    ButtonWhatsApp
}
