import React from "react"
import { ButtonColor, SubHeading, Card, TitleMd} from "../../index";
import { teams } from "../../../assests/dummyData";
import "../ShowCase/ShowCase.css"


const Team = ({teamLimit, title, subTitle, secondaryTitle}) => {
  const limit = teamLimit; // Initial limit
  return (
    <>
      <section className='showcase animated-div'>
        <div className='container'>
          <div className='heading-title'>
            <SubHeading subTitle= {title} title= {subTitle} />
            {
              secondaryTitle ? <TitleMd title= {secondaryTitle} /> : ""
            }
          </div>
          <div className='hero-content grid-3 py'>
            {teams?.slice(0, limit).map((item) => (
              <Card 
                data={item} 
                key={item.id}
                isService={false}
              />
            ))}
          </div>
          {
            !secondaryTitle ? (<div className='card_links'>
            <ButtonColor
              btnName={"View all Members"}
              proVisit={"/team"} 
            />
          </div>) : ""
          }
        </div>
      </section>
    </>
  )
}


export default Team;
