import React from 'react'
import { Link } from "react-router-dom"
import { TitleMd } from "../../index";
import { HiOutlineArrowRight } from "react-icons/hi"
import './Card.css'

const Card = ({ data, caption, show, path, isService, linkedinUrl}) => {
  return (
    <>
      <div className='card'>
        <div className='card-img'>
          <img src={data.cover} alt={data.title} />
        </div>
        <div className='card-details'>
      {
        isService ?  <Link  href={`${data.path}`} className='title-link'>
            <TitleMd title={data.title} />
          </Link> :  <a  href={`${data.path}`} className='title-link'
                  rel="noopener noreferrer" target="_blank">
            <TitleMd title={data.title} />
          </a>
      }
          {caption && (
            <Link href={data.linkedinUrl} rel="noopener noreferrer" target="_blank">
              {caption} <HiOutlineArrowRight className='link-icon' />
            </Link>
          )}
 
          <div className='flex text-center justify-center'>
            <span> {data.catgeory} </span> {data.date && <span> / {data.date}</span>}
          </div>

          {show && (
            <ul>
              {data.desc.map((text, i) => (
                <li key={i}> - {text.text}</li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  )
}
export default Card;