import React from "react";
import MetaData from "../../MetaData";
import { 
    About,
    MissionVission,
    Banner
} from "../../index"

function Home() {
    return (
        <>
            <MetaData title="Techxudo | Your Digital Partner" />
            <About />
            <MissionVission />
            <Banner /> 
        </>
    )
}

export default Home;