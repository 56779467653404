import React, { useState } from 'react';
import styles from '../../../css/Industry.module.css';
import { MissionVissions } from "../../../assests/dummyData";
import { TitleMd, SubHeading } from "../../index";
import "./MissionVission.css"

function MissionVission() {
  const [activeTab, setActiveTab] = useState(MissionVissions[0].title);

  const handleTabClick = (title) => {
    setActiveTab(title);
  };

  const activeIndustry = MissionVissions.find(MissionVission => MissionVission.title === activeTab);

  return (
    <section className={`${styles.industry}`}>
      <SubHeading subTitle="Mission" title="& Vision" />
      <TitleMd title = "We provide a wide range of services that will help you to mold your presence as a digital expert." />
      <div className={styles.tabs}>
        {MissionVissions?.map(industry => (
          <div 
            key={industry.title} 
            className={`${styles.tab} ${activeTab === industry.title ? styles.activeTab : ''}`} 
            onClick={() => handleTabClick(industry.title)}
          >
            {industry.icon}
            <h3>{industry.title}</h3>
            {/* <SecondaryHeading title = {industry.title} />  */}
          </div>
        ))}
      </div>
      <div className={styles.content}>
        <h2>{activeIndustry?.tagLine}</h2>
        {/* <SecondaryHeading title = {activeIndustry.title} />  */}
        <p>{activeIndustry?.content}</p>
      </div>
    </section>
  );
}

export default MissionVission;
