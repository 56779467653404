import { FaLaptopCode, FaMobileAlt, FaEthereum, FaDev, FaBullhorn, FaStore, FaDesktop, FaDatabase, FaServer, FaChartLine,  FaHandshake, FaUserAlt  } from 'react-icons/fa';
import {
    image1,
    image2,
    image3,
    image4,
    image6,
    // alwahid,
    p1,
    p2,
    p3,
    p4,
    Dymonesha,
    rachel,
    Avis,
    tiffani,
    saleha,
    maurice,
    fredie,
    abidali,
    adan,
    asher,
    brian,
    alihassan,
    // rafahsltan,
    bakhtawar,
    babar,
    talha,
    w1, w2, w3, w4, w5, w6, w7,  w8,w9,w10,w11,w12, w13,w14,w15,w16,w17,w18,bube,
} from "./index"
import styles from '../css/Industry.module.css';

const services = [
  { 
    title: "Web Applications", 
    description: "Explore the dynamic world of web applications with our dedicated team. We provide essential custom web design solutions that enhance user experience and functionality. Whether it's a customer portal, online booking system, or interactive web app, we create web applications that align with your business goals", 
    tags: ["APIs", "MEAN", "MERN", "LAMP", "GO Lang"], 
    img: image1 ,
    icon: <FaLaptopCode style={{ color: "#8075e5" }} /> // Green color for web applications
  },
  { 
    title: "Websites", 
    description: "Our dedicated team specializes in creating custom web design solution tailored to meet the unique requirements of your business. Using modern agile techniques, we ensure that your website is not only visually appealing but also highly functional and user-friendly.", 
    tags: ["Responsive", "UI/UX", "Ranking", "Custom"], 
    img: image2 ,
    icon: <FaDesktop style={{ color: "#8075e5" }} /> // Blue color for websites
  },
  { 
    title: "E-commerce Stores", 
    description: "For those venturing into the realm of e-commerce, our E-commerce solutions are designed to streamline the process. Our dedicated team ensures that your online store is fully functional and visually captivating. We prioritize creating an engaging shopping experience for your customers from the first click to the final purchase.", 
    tags: ["Shopify", "Magento", "WooCommerce"], 
    img: image3 ,
          icon: <FaStore style={{ color: "#8075e5" }} /> // Red color for e-commerce stores
  },
  { 
    title: "CMS Development", 
    description: "Explore the vast potential of CMS with our dedicated team. We provide essential custom web design solutions that leverage the latest trends and techniques associated with this powerful content management system. We aim to create a website that aligns perfectly with your brand and business objectives.", 
    tags: ["WordPress", "Drupal", "Joomla", "Wix", "Squarespace"], 
    img: image4 ,
    icon: <FaDatabase style={{ color: "#8075e5" }} /> // Purple color for CMS development
  },
  { 
    title: "CRM Development", 
    description: "Boost efficiency with our CRM solutions. Our dedicated team uses modern agile techniques to create tailored applications that streamline your business processes. From project management tools to custom software, we develop CRM applications that cater to your specific needs.", 
    tags: ["Salesforce", "Zoho", "Custom CRM"], 
    img: image4 ,
          icon: <FaChartLine style={{ color: "#8075e5" }} /> // Orange color for CRM development
  },
  { 
    title: "ERP Development", 
    description: "Optimize your business processes with our custom ERP development services, designed to enhance efficiency and scalability. Our solutions integrate seamlessly with your existing systems, providing real-time data insights and improved decision-making. Partner with us to streamline operations and drive growth with cutting-edge ERP technology.", 
    tags: ["SAP", "Odoo", "Custom ERP"], 
    img: image1,
          icon: <FaServer style={{ color: '#8075e5' }} /> // Yellow color for ERP development
  },
  { 
    title: "Mobile Application Development", 
    description: "Engage your audience on the go with our mobile application solutions. Our dedicated team ensures your mobile app stands out in the crowded marketplace. From intuitive user interfaces to seamless functionality, we prioritize creating mobile applications that enhance user experience and align with your brand identity", 
    tags: ["Swift", "Kotlin", "React Native", "Flutter"], 
    img: image6 ,
          icon: <FaMobileAlt style={{ color: '#8075e5' }} /> // Green color for mobile apps
  },
  { 
    title: "Smart Contract Development", 
    description: "Embrace the future of secure and efficient transactions with our expertise in smart contracts. Our dedicated team integrates cutting-edge blockchain technology into your digital framework, ensuring transparency, security, and automation in your contractual agreements.", 
    tags: ["Ethereum", "Solidity", "DeFi"], 
    img: image1 ,
          icon: <FaEthereum style={{ color: '#8075e5' }} /> // Ethereum color
  },
  { 
    title: "DevOps", 
    description: "Enhance your software development lifecycle with our expert DevOps services, ensuring seamless integration, continuous delivery, and robust automation. Our solutions optimize workflows, reduce deployment times, and improve system reliability. Partner with us to accelerate innovation and achieve operational excellence with top-tier DevOps practices.", 
    tags: ["CI/CD", "Docker", "Kubernetes"], 
    img: image1 ,
          icon: <FaDev style={{ color: '#8075e5' }} /> // Dark blue color for DevOps
  },
  { 
    title: "Digital Marketing", 
    description: "Enhance your digital visibility and increase your online presence with our SEO services. Our dedicated team employs modern agile techniques to optimize your website for search engines, ensuring your business stands out in search engine rankings. From keyword optimization to content strategy, your SEO needs are covered.", 
    tags: ["SEO", "PPC", "Social Media"], 
    img: image1 ,
          icon: <FaBullhorn style={{ color: '#8075e5' }} /> // Pink color for digital marketing
  },
  { 
    title: "Graphic Designing", 
    description: "Make a lasting impression with visually stunning designs crafted by our expert graphic design team. We transform your ideas into visual masterpieces, ensuring your brand communicates effectively through eye-catching graphics. From logos to marketing materials, our graphic design services are tailored to elevate your brand aesthetics", 
    tags: ["SEO", "PPC", "Social Media"], 
    img: image1 ,
          icon: <FaBullhorn style={{ color: '#8075e5' }} /> // Pink color for digital marketing
  },
  { 
    title: "Content Writing", 
    description: "Tell your brand story effectively with our dedicated content writing team. We create compelling and engaging content that informs your audience and resonates with your brand identity. Whether it's website content, blog posts, or social media copy, our content writing services are designed to captivate your audience.", 
    tags: ["SEO", "PPC", "Social Media"], 
    img: image1 ,
          icon: <FaBullhorn style={{ color: '#8075e5' }} /> // Pink color for digital marketing
  },

]


// Mission And Vission
const MissionVissions = [
  { 
    title: "Mission",
    tagLine: "Empowering businesses through derived strategies and integrating best practices",
    icon: <FaChartLine style={{ color: '#17a2b8' }} className={styles.icon} />, 
    content: "Our mission is to empower businesses and individuals to thrive in the digital era. We are dedicated to delivering innovative and customized solutions that meet the current demands of the ever-evolving digital landscape and anticipate the challenges of tomorrow. Through our expertise in IT System Custom Software Development and a comprehensive suite of digital services, we strive to be the catalyst for your success. We are committed to fostering long-lasting partnerships, driving growth, and making a meaningful impact on the digital future." 
  },
  { 
    title: "Vision", 
    tagLine: "We assure excellence and we believe in delivering value to our customer",
    icon: <FaUserAlt  style={{ color: '#007bff' }} className={styles.icon} />, 
    content: "Our vision is to be a trailblazer in shaping the digital landscape, setting new standards for excellence, and driving transformative change. We transform a world where businesses leverage the full potential of technology to achieve heights of success. By constantly pushing the boundaries of innovation, embracing emerging technologies, and fostering a culture of continuous learning, we aim to be the go-to partner for those aspiring to lead in the digital realm. Our vision is not just about creating solutions but crafting a digital legacy that inspires and transforms industries, one client success story at a time." 
  },
  { 
    title: "Why Choose Us?", 
    tagLine: "Let’s uncover the pathway to your digital success ! Shake Hands with Techxudo.",
    icon: <FaHandshake  style={{ color: '#007bff' }} className={styles.icon} />, 
    content: (
      <p><b>Proven Expertise: </b> Our seasoned team brings a wealth of knowledge and expertise, ensuring that every project is executed with precision and excellence. <b>Client Satisfaction:</b> With a remarkable 99% client satisfaction rate, we take pride in translating your vision into a digital reality that drives success <b>Comprehensive Solutions:</b> Whether developing cutting-edge software, optimizing your online presence, or diving into the world of smart contracts, Techxudo offers a comprehensive suite of services tailored to your unique needs</p>
    )  },
];

const showcases = [
  {
    id: 1,
    title: "Moakhat",
    cover: w1,
    catgeory: "WEB APPLICATION",
    path: "https://moakhat.org/en"
  },
  {
    id: 2,
    title: "Al Wahid Quranic Academy",
    cover: w7,
    catgeory: "WEB APPLICATION - MERN",
    path: "https://alwahidqa.com/"
  },
  {
    id: 3,
    title: "Bube",
    cover: bube,
    catgeory: "Freelance Platform",
    path: "https://www.figma.com/proto/SKruvEJog84HgbPPlSFOTJ/Figma_App?page-id=0%3A1&amp%3Bnode-id=286%3A2127&amp%3Bviewport=579%2C350%2C0.1&amp%3Bscaling=min-zoom&amp%3Bstarting-point-node-id=1%3A30&node-id=1-30&starting-point-node-id=1%3A30"
  },
  {
    id: 13,
    title: "The Rasooth Cleaning",
    cover: w4,
    catgeory: "CMS",
    path: "https://therasoothecleaning.com/"
  },
  {
    id: 4,
    title: "The Blooming Mum",
    cover: w5,
    catgeory: "CMS - WordPress",
    path: "https://thebloomingmum.com/"
  },
  {
    id: 5,
    title: "Design Dwell Rich",
    cover: w2,
    catgeory: "CMS - WordPress",
    path: "https://designz.dwell-rich.com/"
  },
  {
    id: 6,
    title: "Cookies by Freddie",
    cover: w10,
    catgeory: "CMS",
    path: "https://cookiesbyfreddie.com/"
  },
  {
    id: 7,
    title: "Credible Engineer",
    cover: w6,
    catgeory: "CMS - WordPress",
    path: "https://www.credibleengineers.com/"
  },
  {
    id: 8,
    title: "Modify My Beauty",
    cover: w12,
    catgeory: "CMS - WordPress",
    path: "https://modifymybeauty.com/"
  },
  {
    id: 9,
    title: "Denim BMC",
    cover: w8,
    catgeory: "CMS - WordPress",
    path: "https://denimbmc.com/"
  },
  {
    id: 10,
    title: "Little Wish Dish",
    cover: w11,
    catgeory: "CMS - WordPress",
    path: "https://littlewhitedish.com/"
  },
  {
    id: 11,
    title: "Seib Insurance",
    cover: w9,
    catgeory: "CMS - WordPress",
    path: "https://www.seibinsurance.com/"
  },
  {
    id: 12,
    title: "Travel Tourist",
    cover: w13,
    catgeory: "WEB APPLICATION - MERN",
    path: "https://syed-bakhtawar-fahim.github.io/TouristWebite_Portfolio/"
  },
  {
    id: 13,
    title: "CCG Consultant",
    cover: w14,
    catgeory: "CMS - WordPress",
    path: "https://www.ccgconsultants.org/"
  },
  {
    id: 14,
    title: "Dwell Rich",
    cover: w3,
    catgeory: "CMS - WordPress",
    path: "https://dwell-rich.com/"
  },
  {
    id: 15,
    title: "Paltalliance",
    cover: w16,
    catgeory: "CMS - WordPress",
    path: "https://peltalliance.com/"
  },
  {
    id: 16,
    title: "Number Puzzle Game",
    cover: w17,
    catgeory: "ANDRIOD APPLICATION",
    path: "https://play.google.com/store/apps/details?id=com.Sameerstg.-numberPuzzleGame"
  },
  {
    id: 17,
    title: "Datrix",
    cover: w17,
    catgeory: "ANDRIOD APPLICATION",
    path: "https://play.google.com/store/apps/details?id=com.sameerstg"
  },
  {
    id: 18,
    title: "Be Leet",
    cover: w18,
    catgeory: "ANDRIOD APPLICATION",
    path: "https://play.google.com/store/apps/details?id=com.Sameerstg.BeLeet"
  },
  {
    id: 19,
    title: "Pecision Firearms",
    cover: w15,
    catgeory: "CMS - WordPress",
    path: "https://2ndtononeprecisionfirearms.com/"
  },
  // {
  //   id: 4,
  //   title: "dd",
  //   cover: image1,
  //   catgeory: "CMS",
  //   path: "dddd"
  // },

]

const reviews = [
  {
    id: 1,
    image:
      'https://images.unsplash.com/photo-1605462863863-10d9e47e15ee?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    name: 'John Doe',
    text: 'I was extremely pleased with the quality of the product. It exceeded my expectations and provided great value for the price.',
  },
  {
    id: 2,
    image:
      'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    name: 'Jane Smith',
    text: 'The customer service was excellent. They were responsive and helpful throughout the entire process, making it a smooth experience for me.',
  },
  {
    id: 3,
    image:
      'https://images.unsplash.com/photo-1463453091185-61582044d556?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    name: 'Alex Johnson',
    text: 'The attention to detail in their work is impressive. Every aspect of the project was handled with precision and care. I highly recommend their services.',
  },
  {
    id: 4,
    image:
      'https://plus.unsplash.com/premium_photo-1671823917954-dc943c1bd9df?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    name: 'Emily Davis',
    text: 'The team demonstrated a deep understanding of my requirements. They were able to capture the essence of my vision and deliver a product that exceeded my expectations.',
  },
  {
    id: 5,
    image:
      'https://images.unsplash.com/photo-1564564321837-a57b7070ac4f?q=80&w=1776&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    name: 'David Miller',
    text: "The product not only met but exceeded my expectations. It's clear that the team is dedicated to delivering high-quality work. I'm a satisfied customer.",
  },
];

export const clients = [
  { logo: p1 },
  { logo: p2 },
  { logo: p3 },
  { logo: p4 }, 
];


export const clientReviews = [
  {
    name: 'Avis Brown',
    image: Avis,
    text: 'I highly recommend Syed’s service. His website design exceeded my expectations.'
  },
  {
    name: 'Frederique Bekale',
    image: fredie,
    text: "Syed was the project manager for the rebranding of my business and I’m so happy with the result!",
  },
  {
    name: 'Tiffani Medcalf',
    image: tiffani,
    text: "Talha is AMAZING to work with! He did a wonderful job communicating and designing our rental website."
  },
  {
    name: 'Saleha Ali Khan',
    image: saleha,
    text: "Syed is an excellent website developer someone who is passionate about creating high-quality websites.",
  },
  {
    name: 'Maurice Malon',
    image: maurice,
    text: "Syed is a very skilled and well-rounded expert. I've been collaborating with him on my denimbmc.com blog."
  },
  {
    name: 'DyMonesha Brown',
    image: Dymonesha,
    text: "Syed is a great person to work with. He goes above and beyond to complete asthethically pleasing website designs."
  },
  {
    name: 'Rachel Kalien',
    image: rachel,
    text: "It has been great all around experience with Syed. He was so kind and explained me many things that I didn't understand."
  }
];

export const teams = [
  {
    id: 1,
    title: "Syed Talha Shah",
    cover: talha,
    catgeory: "Cheif Executive Officer",
    path: "https://www.linkedin.com/in/syed-talha-shah/"
  },
  {
    id: 2,
    title: "Syed Bakhtawar Fahim",
    catgeory: "Cheif Technology Officer",
    cover: bakhtawar,
    path: "https://www.linkedin.com/in/bigdataengineer/"
  },
  // {
  //   id: 3,
  //   title: "Rafah Sultan",
  //   catgeory: "Cheif Operation Officer",
  //   cover: rafahsltan,
  //   path: "https://www.linkedin.com/in/bigdataengineer/"
  // },
  {
    id: 4,
    title: "Brian Lewis",
    catgeory: "Digital Marketing Lead",
    cover: brian,
    path: "https://www.linkedin.com/in/bigdataengineer/"
  },
  {
    id: 5,
    title: "Muhammad Huzaifa",
    catgeory: "Frontend Developer",
    cover: image1,
    path: "https://www.linkedin.com/in/bigdataengineer/"
  },
  {
    id: 6,
    title: "Babar Khan",
    catgeory: "Software Engineer - I",
    cover: babar,
    path: "https://www.linkedin.com/in/bigdataengineer/"
  },
  {
    id: 7,
    title: "Ashar Ali",
    catgeory: "BD executive",
    cover: asher,
    path: "https://www.linkedin.com/in/bigdataengineer/"
  },
  {
    id: 8,
    title: "Adan Khan",
    catgeory: "Software Engineer",
    cover: adan,
    path: "https://www.linkedin.com/in/bigdataengineer/"
  },
  {
    id: 9,
    title: "Nabeel Azam",
    catgeory: "Digital Marketing",
    cover: abidali,
    path: "https://www.linkedin.com/in/bigdataengineer/"
  },
  {
    id: 10,
    title: "Sardar Abid",
    catgeory: "SEO Lead",
    cover: alihassan,
    path: "https://www.linkedin.com/in/bigdataengineer/"
  },
]
export  {
    services,
    MissionVissions,
    showcases,
    reviews
}