import React from "react"
import "./Banner.css"
import { BannerTitleLg, BannerTitlemd } from "../../index";

const Banner = () => {
  return (
    <>
      <section className='banner animated-div'>
        <div className='container'>
          <div>
            <BannerTitleLg title='Connect Today, Conquer Tomorrow.' /> <br />
            <BannerTitlemd title='Our dedicated team is committed to providing
personalized solutions that align with your unique needs.' />
          </div>
          <div className="banner-request">
          {/* https://appt.link/meet-with-syed-bakhtawar-fahim
 */}
            {/* <button className='button-primary'>Request a call-back</button> */}
            <a className='bg-primary px-6 py-2.5 rounded-md text-white cursor-pointer no-underline transition duration-500 ease-in-out hover:bg-transparent hover:text-[#fff] hover:font-medium text-sm p-2 md:text-base md:p-2.3 lg:text-lg lg:p-2' href = "https://appt.link/meet-with-syed-bakhtawar-fahim" rel="noopener noreferrer" target="_blank">Request a call-back</a>
          </div>
        </div>
      </section>
    </>
  )
}

export default Banner
